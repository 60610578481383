<template>
  <div>
    <archive></archive>
  </div>
</template>

<script>
import Archive from "./../../../components/bulksms/archives/index";
export default {
  data: () => ({
  }),
  components: {
    Archive,
  },
};
</script>