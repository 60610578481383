<template>
  <v-dialog max-width="500px" v-model="dialog.display">
    <v-card>
      <v-card-title>
        <div class="text-subtitle-1 font-weight-bold">
          {{ $t("bulksms.archive.download.title") }}
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-3">
        <v-container fluid grid-list-md>
          <div class="font-weight-bold">{{ $t('bulksms.campaign.fields.email.helper') }}</div>
          <v-text-field
            v-model="form.email"
            :label="$t('bulksms.campaign.fields.email.title')"
            append-icon="mdi-at"
            @input="delayTouch($v.form.email)"
            @blur="$v.form.email.$touch()"
            :error-messages="emailErrors"
            persistent-hint
            class="font-weight-bold"
            maxlength="255"
          ></v-text-field>
        </v-container>
      </v-card-text>
      <v-card-actions class="px-5">
        <v-spacer></v-spacer>
        <v-btn text @click="cancel()">{{ $t("btn.cancel") }}</v-btn>
        <v-btn
          text
          color="primary"
          :loading="loading"
          @click="download()"
          :disabled="$v.form.$invalid"
          >{{ $t("btn.confirm") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, maxLength, helpers } from "vuelidate/lib/validators";
import DelayTouchMixin from "./../../../mixins/form-delay-touch";
import Vue from "vue";
import { mapActions } from "vuex";

const isValidStringEmails = (value) => {
  if (!helpers.req(value)) {
    return true;
  }

  let isValid = true;
  const emails = value.replace(/;$/).split(";");

  emails.map((email) => {
    if (!Vue.$utils.isValidEmail(email)) {
      isValid = false;
    }
  });

  return isValid;
};

export default {
  mixins: [DelayTouchMixin],
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
        };
      },
    },
    archive: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  validations: {
    form: {
      email: {
        required,
        maxLength: maxLength(255),
        isValidStringEmails: isValidStringEmails,
      },
    },
  },
  data: () => ({
    form: {
      email: null,
    },
    loading: false,
  }),
  methods: {
    async download() {
      this.loading = true;

      try {
        await this.request({
          url: `/api/providers/bulksms/pushes/archives/${this.archive.id}/receive`,
          method: "POST",
          data: { email: this.emailStringToArray(this.form.email) },
        });
        this.$emit("success");
        this.notify({ message: this.$t("bulksms.archive.download.success") });
        this.dialog.display = false;
      } catch (error) {
        let message = "error_codes.error_occured";
        if (error && error.response) {
          const status = error.response.status;
          const data = error.response.data;
          if (status === 422) {
            if (data.details.code === 400) {
              message = "error_codes.bad_request";
            } else if (data.details.code === 422) {
              message = data.details.data.message;
              this.dialog.display = false;
            }
          }
        }
        this.notify({ message: this.$t(message) });
      }

      this.loading = false;
    },
    cancel() {
      this.dialog.display = false;
    },
    emailStringToArray(email) {
      const emails = email.replace(/;$/).split(";");
      return this._.uniq(emails);
    },
    ...mapActions({ request: "request", notify: "notification/notify" }),
  },
  computed: {
    emailErrors() {
      const errors = [];

      if (!this.$v.form.email.$dirty) return errors;

      !this.$v.form.email.required &&
        errors.push(this.$t("bulksms.campaign.fields.email.required"));

      !this.$v.form.email.maxLength &&
        errors.push(
          this.$t(
            "bulksms.campaign.fields.email.maxLength",
            this.$v.form.email.$params.max
          )
        );

      !this.$v.form.email.isValidStringEmails &&
        errors.push(this.$t("bulksms.campaign.fields.email.invalid"));

      return errors;
    },
  },
};
</script>