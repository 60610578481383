<template>
  <div>
    <div class="text-h6 font-weight-medium mb-4">
      {{ $t("bulksms.archive.title") }}
    </div>
    <div class="mt-4">
      <v-card class="rounded-md mb-4">
        <div class="d-flex align-center justify-start px-4 py-3 flex-wrap">
          <v-btn
            depressed
            @click="refreshItems()"
            :loading="loading.refresh"
            class="mr-2"
          >
            <v-icon left>mdi-refresh</v-icon>
            {{ $t("btn.refresh") }}
          </v-btn>
        </div>
      </v-card>
      <v-card>
        <v-card-title>
          <div class="font-weight-bold text-subtitle-1">
            {{ $t("bulksms.archive.list.title") }}
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-data-table
          :loading="loading.list"
          :headers="headers"
          :items="items"
          :options.sync="pagination"
          :footer-props="footerProps"
          :items-per-page="itemPerPage"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :server-items-length="meta.total"
        >
          <template v-slot:body="{ items }" v-if="!_.isEmpty(items)">
            <tbody>
              <template v-for="item in items">
                <v-hover :key="item.id" v-slot:default="{ hover }">
                  <tr>
                    <td>
                      <v-chip class="table-chips text-body-2" color="fourth">
                        <span class="font-weight-bold">{{
                          $moment(item.archiveDate)
                            .format("YYYY-MMMM")
                            .toUpperCase()
                        }}</span>
                      </v-chip>
                    </td>
                    <td>
                      <v-chip class="table-chips text-body-2" color="fourth">
                        <span>{{ $utils.pad(item.lineCount, 2) }}</span>
                      </v-chip>
                    </td>
                    <td>
                      <v-chip class="table-chips text-body-2" color="fourth">
                        <span>{{ $utils.pad(item.fileCount, 2) }}</span>
                      </v-chip>
                    </td>
                    <td>
                      <v-chip class="table-chips text-body-2" color="fourth">
                        <span>{{ $utils.pad(item.fileSize, 2) }}</span>
                      </v-chip>
                    </td>
                    <td>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="downloadArchive(item)"
                            x-small
                            icon
                            v-bind="attrs"
                            v-on="on"
                            v-show="hover || $vuetify.breakpoint.mdAndDown"
                          >
                            <v-icon color="primary"
                              >mdi-archive-arrow-down-outline</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>{{ $t("bulksms.archive.btn.download") }}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </v-hover>
              </template>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
      <archive-download
        :dialog="dialog.download"
        :archive="archive"
      ></archive-download>
    </div>
  </div>
</template>

<script>
import ListMixin from "./../../../mixins/commons/list";
import ArchiveDownload from "./download";
export default {
  mixins: [ListMixin],
  metaInfo() {
    return {
      title: this.$t("bulksms.archive.title"),
    };
  },
  data: () => ({
    path: "/api/providers/bulksms/pushes/archives",
    dialog: {
      download: {
        display: false,
      },
    },
    archive: {},
  }),
  methods: {
    setHeaders() {
      this.headers = [
        {
          text: this.$t("bulksms.campaign.fields.created.title"),
          value: "archiveDate",
          align: "start",
          sortable: true,
        },
        {
          text: this.$t("bulksms.campaign.fields.lines.title"),
          value: "lineCount",
          align: "start",
          sortable: false,
        },
        {
          text: this.$t("bulksms.campaign.fields.fileCount.title"),
          value: "fileCount",
          align: "start",
          sortable: false,
        },
        {
          text: this.$t("bulksms.campaign.fields.size.title"),
          value: "fileSize",
          align: "start",
          sortable: false,
        },
        {
          text: "",
          align: "start",
          width: 150,
          sortable: false,
        },
      ];
    },
    downloadArchive(item) {
      this.archive = item;
      this.dialog.download.display = true;
    },
  },
  components: {
    ArchiveDownload,
  },
};
</script>